import React from "react";
//import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
//import backgroundImage1 from "../assets/mountain-runner.jpg";

// const useStyles = makeStyles(theme => ({
//   background: {
//     backgroundImage: "url(https://source.unsplash.com/random)"
//   }
// }));

export default function Profile() {
  //const classes = useStyles();
  return (
    <Grid container>
      <Typography>Profile</Typography>
    </Grid>
  );
}
