import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import ProductHeroLayout from "./ProductHeroLayout";
import backgroundImage from "../assets/mountain-runner.jpg";
import stravaBadge from "../assets/api_logo_cptblWith_strava_stack_white.svg";
import fitbitBadge from "../assets/works-with-fitbit.png";

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center"
  },
  button: {
    minWidth: 350,
    marginTop: "30px"
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10)
    }
  },
  stravaLogo: {
    height: "4.5em"
  },
  fitbitLogo: {
    height: "3.5em"
  },
  compatibleLogosContainer: {
    marginTop: "2em"
  }
});

function ProductHero(props) {
  const {classes} = props;

  return (
    <Box boxShadow={3}>
      <ProductHeroLayout
        backgroundClassName={classes.background}
        backgroundImage={backgroundImage}
      >
        <Typography color="inherit" align="left" variant="h2" marked="left">
          Race
        </Typography>
        <Typography color="inherit" align="left" variant="h2" marked="center">
          <i>any</i> distance
        </Typography>
        <Typography color="inherit" align="left" variant="h2" marked="center">
          <i>any</i> time
        </Typography>
        <Typography color="inherit" align="left" variant="h2" marked="center">
          <i>any</i> place
        </Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          className={classes.buttonContainer}
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              component="a"
              href="/races"
            >
              Find a race
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              className={classes.button}
              component="a"
              href="/races"
            >
              Submit a result
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.compatibleLogosContainer}
        >
          <img
            className={classes.stravaLogo}
            src={stravaBadge}
            alt="compatible with strava"
          />
          <img
            className={classes.fitbitLogo}
            src={fitbitBadge}
            alt="works with fitbit"
          />
        </Grid>
      </ProductHeroLayout>
    </Box>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHero);
