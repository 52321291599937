import React from "react";
import {Route, Switch} from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
//import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import Signup from "./containers/Signup";
import Races from "./containers/races/Races";
import Race from "./containers/races/Race";
import NewRace from "./containers/races/NewRace";
import AuthCallback from "./containers/AuthCallback";
import Connections from "./containers/Connections";
import Profile from "./containers/Profile";
import ResetPW from "./components/ResetPWForm";
import Settings from "./containers/Settings";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/login/reset">
        <ResetPW />
      </Route>
      <Route exact path="/logout">
        <Logout />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <AuthenticatedRoute exact path="/races/new">
        <NewRace />
      </AuthenticatedRoute>
      <Route exact path="/races">
        <Races />
      </Route>
      <Route exact path="/races/:raceId">
        <Race />
      </Route>
      <AuthenticatedRoute exact path="/profile/">
        <Profile />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/connections">
        <Connections />
      </AuthenticatedRoute>
      <Route exact path="/strava/auth_callback">
        <AuthCallback />
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
