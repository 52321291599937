import React, {useState} from "react";
//import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import {useAppContext} from "../libs/contextLib";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";

export default function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const {currentUserProfile} = useAppContext();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={currentUserProfile.num_messages} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        transformOrigin={{vertical: "top", horizontal: "center"}}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link href="/profile" color="inherit">
            You have a new message!
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="/settings" color="inherit">
            Another message here.
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="/logout" color="inherit">
            Wow, so many messages!
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
