import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

import ButtonSpinner from "../../components/ButtonSpinner";

import ProductHeroLayout from "../../components/ProductHeroLayout";
import ResultsTable from "../../components/ResultsTable";
import {useParams} from "react-router-dom";
import {API} from "aws-amplify";
import {onError} from "../../libs/errorLib";
import {
  timestampToDate,
  prettyPrintDistance,
  imgUrl
} from "../../libs/helperLib";

// // mock the API response for now
//import apiResponse from "../../mocks/races/get";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F5F5F5"
  },
  background: {
    backgroundColor: "#7fc7d9",
    backgroundPosition: "center",
    opacity: "0.80"
  },
  item: {
    display: "flex",
    flexDirection: "row"
  },
  button: {
    minWidth: 200
  },
  raceDetailsContainer: {
    padding: "3em"
  },
  raceDescriptionBody: {
    paddingTop: "1em"
  }
}));

export default function Race() {
  const classes = useStyles();
  const {raceId} = useParams();
  const [race, setRace] = useState({});
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registering, setRegistering] = useState(false);
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    setLoading(true);
    function loadRace() {
      return API.get("virtual-tm", `/races/${raceId}`);
    }

    async function onLoad() {
      try {
        let race = await loadRace();
        let banner_image = race.data.race.bg_img;

        if (banner_image) {
          race.data.race.bg_img = imgUrl(banner_image);
        }

        // setRace(apiResponse.data.race);
        // setResults(apiResponse.data.results);
        setRace(race.data.race);
        setResults(race.data.results);
        setLoading(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [raceId]);

  const handleRegisterClick = async event => {
    try {
      setRegistering(true);
      await API.post("virtual-tm", `/races/${raceId}/register`);
      setRegistered(true);
    } catch (e) {
      console.error(e);
      onError(e);
    }
    setRegistering(false);
  };

  return (
    <React.Fragment>
      <ProductHeroLayout
        backgroundClassName={classes.background}
        backgroundImage={race.bg_img}
      >
        <Grid container direction="column" align="center" spacing={3}>
          <Typography color="inherit" align="center" variant="h2" marked="left">
            {!loading && race.title}
          </Typography>
          <Typography
            color="inherit"
            align="center"
            variant="h6"
            marked="center"
          >
            {!loading
              ? timestampToDate(race.s_date) +
                " - " +
                timestampToDate(race.e_date)
              : "Loading..."}
          </Typography>
          <Grid item>
            <Typography color="inherit" variant="subtitle1">
              hosted by | {!loading ? race.created_by.name : "Loading..."}
            </Typography>
            {!loading && <Avatar src={race.created_by.avatar_url} />}
          </Grid>
          <Grid item>
            <ButtonSpinner
              onClick={() => handleRegisterClick()}
              loading={registering}
              color="secondary"
              variant="contained"
              size="large"
              className={classes.button}
              component="a"
            >
              {registered ? "REGISTERED" : "REGISTER"}
            </ButtonSpinner>
          </Grid>
        </Grid>
      </ProductHeroLayout>

      <Grid
        container
        direction="column"
        className={classes.raceDetailsContainer}
      >
        <Typography variant="h3">{!loading && race.title}</Typography>
        <Grid container direction="row">
          <Typography variant="subtitle2">
            {!loading && race.a_type} |{" "}
            {!loading && prettyPrintDistance(race.distance)} |{" "}
            <Link
              component="button"
              onClick={() => {
                handleRegisterClick();
              }}
            >
              {registered ? "REGISTERED" : "REGISTER"}
            </Link>
          </Typography>
        </Grid>
        <Grid item className={classes.raceDescriptionBody}>
          <Typography variant="body2" gutterBottom>
            {!loading && race.desc}
          </Typography>
        </Grid>
      </Grid>

      <Container>
        {!loading && <ResultsTable race={race} results={results} />}
      </Container>
    </React.Fragment>
  );
}
