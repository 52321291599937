import React, {useState} from "react";
//import PropTypes from "prop-types";
import {Formik, Form, Field} from "formik";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Typography, Grid} from "@material-ui/core";
import * as yup from "yup";
import {Auth} from "aws-amplify";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const validationSchema = yup.object({
  confirmationCode: yup
    .string()
    .required("Required. Check your email for code"),
  newPassword: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Password must match.")
    .required("Required")
});

export const ResetPasswordStep2 = ({
  formData,
  setFormData,
  nextStep,
  prevStep
}) => {
  const classes = useStyles();
  const [direction, setDirection] = useState("back");
console.log(direction);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Confirmation Code
        </Typography>
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={values => {
            setFormData(values);
            Auth.forgotPasswordSubmit(
              values.email,
              values.confirmationCode,
              values.newPassword
            )
              .then(data => nextStep())
              .catch(err => alert(err.message));
          }}
        >
          {({errors, touched}) => (
            <Form>
              <Grid container className={classes.formContainer} spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="confirmationCode"
                    label="Confirmation Code"
                    as={TextField}
                    fullWidth
                    error={touched.confirmationCode && errors.confirmationCode}
                    helperText={
                      touched.confirmationCode && errors.confirmationCode
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="newPassword"
                    label="New Password"
                    as={TextField}
                    fullWidth
                    type="password"
                    error={touched.newPassword && errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="confirmNewPassword"
                    label="Confirm Password"
                    as={TextField}
                    fullWidth
                    type="password"
                    error={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                    helperText={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                  />
                </Grid>
              </Grid>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => setDirection("forward")}
                  fullWidth
                >
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
