import React from "react";
import "./LoaderButton.css";
import {API} from "aws-amplify";
/*

import StravaLogin from "<path>/StravaLogin";

<StravaLogin
  redirectUrl="http://localhost:3000/strava/auth_callback/"
  onFailure={onFailed}
  onSuccess={onSuccess}
  requestTokenUrl="/auth_callback"
/>

  function onFailed(){console.log("FAILED");}
  function onSuccess(){console.log("SUCCESS");}
*/

StravaLogin.defaultProps = {
  dialogWidth: 600,
  dialogHeight: 400
};

let windowObjectReference = null;
let previousUrl = null;

export default function StravaLogin(props) {
  function handleSubmit(event) {
    event.preventDefault();
    const args =
      "client_id=45623&" +
      `redirect_uri=${props.redirectUrl}&` +
      "response_type=code&" +
      "state=foobar&" +
      "scope=activity:read_all&" +
      "approval_type=force";

    // prompt user for permission to read their data from Strava
    openSignInWindow(
      "https://www.strava.com/oauth/authorize?" + args,
      "Login with Strava"
    );
  }

  /*
    Open popup window and listen for
  */
  function openSignInWindow(url, name) {
    // remove any existing event listeners
    window.removeEventListener("message", postMessageHandler);

    // window features (size, open as popup, etc)
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
           or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
           then we load it in the already opened secondary window and then
           we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
           is not closed; therefore, we can bring it back on top of any other
           window with the focus() method. There would be no need to re-create
           the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", postMessageHandler, false);
    // assign the previous URL
    previousUrl = url;
  }

  // Handle messages sent sent to the popup window
  const postMessageHandler = async event => {
    console.log(event);
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== window.location.origin) {
      return;
    }
    const {data} = event;
    // get the URL params and redirect to our server to auth/login

    if (data.source === "signupandrace") {
      // get the URL params and redirect to our server to auth/login
      const {payload} = data;
      console.log(payload);

      const args = {
        queryStringParameters: payload
      };

      console.log("CALL " + props.requestTokenUrl);

      API.get("virtual-tm", props.requestTokenUrl, args)
        .then(response => {
          props.onSuccess();
        })
        .catch(error => {
          props.onFailure();
        });
    }
  };

  return (
    <button onClick={handleSubmit}>
      <img src="btn_strava_connectwith_orange@2x.png" alt="connectWithStrava" />
    </button>
  );
}
