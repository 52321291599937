import {format} from "date-fns";
import config from "../config";

const distances = {
  400: "400 meters",
  800: "800 meters",
  1000: "1 kilometer",
  1609: "1 mile",
  3218: "2 mile",
  5000: "5 kilometer",
  10000: "10 kilometer",
  21097: "Half Marathon",
  42195: "Marathon"
};

export function imgUrl(keyName, opts = {}, bucketName = config.s3.BUCKET) {
  let args = {
    bucket: bucketName,
    key: keyName
  };
  let imageRequest = JSON.stringify({...args, ...opts});
  return `${config.s3.IMG_URL}${btoa(imageRequest)}`;
}

/*  Takes in a timestamp, outputs a human readible string.
    e.g. timestampToDate(1593673200000) => "July 2nd, 2020"
*/
export function timestampToDate(timestamp) {
  let d = new Date(timestamp);
  return format(d, "MMMM do, yyyy");
}

export function prettyPrintDistance(distance) {
  return distances[distance] || "5 kilometers";
}

/*
 * returns string representation of pace.
 *   time - in seconds
 *   distance - in meters
 *   isMetric - true for kilometer pace, false for mile pace
 */
export function pacePerDistance(time, distance, isMetric = false) {
  let secondsPerMeter = time / distance;
  let secondsPerMile = secondsPerMeter * 1609;
  let secondsPerKilometer = secondsPerMeter * 1000;

  return isMetric
    ? timeDisplay(secondsPerKilometer) + "/km"
    : timeDisplay(secondsPerMile) + "/mi";
}

export function timeDisplay(s) {
  console.log(s);
  let date = new Date(null);
  date.setSeconds(s);
  // Convert seconds to time string
  let iso = date.toISOString().substr(11, 8);
  // trim any leading zeroes or colons
  return iso.replace(/^[:0]+/, "");
}

// compare function used to sort arbitrary array of objects
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}
