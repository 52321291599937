import React from "react";
import {Formik, Form, Field} from "formik";
import {
  TextField,
  //Container,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import {Auth} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
//import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8)
  }
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
});

export const ResetPasswordStep1 = ({formData, setFormData, nextStep}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(values);
          Auth.forgotPassword(values.email)
            .then(data => nextStep())
            .catch(err => alert(err.message));
        }}
        validationSchema={validationSchema}
      >
        {({errors, touched}) => (
          <Form>
            <Grid container direction="column" alignItems="center" spacing={3}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="email"
                  name="email"
                  label="Email *"
                  as={TextField}
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Send Confirmation
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
