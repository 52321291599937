import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function EventFilter(props) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">Distance</FormLabel>
      <RadioGroup
        value={props.distanceStatus}
        onClick={props.handleDistanceChange}
      >
        <FormControlLabel
          value="under1m"
          control={
            <Radio
              name="under1m"
              checked={props.distanceStatus === "under1m"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Up to 1 Mile
            </Typography>
          }
        />
        <FormControlLabel
          value="under5k"
          control={
            <Radio
              name="under5k"
              checked={props.distanceStatus === "under5k"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              1 Mile to 5k
            </Typography>
          }
        />
        <FormControlLabel
          value="under15k"
          control={
            <Radio
              name="under15k"
              checked={props.distanceStatus === "under15k"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              5k to 15k
            </Typography>
          }
        />
        <FormControlLabel
          value="underHalf"
          control={
            <Radio
              name="underHalf"
              checked={props.distanceStatus === "underHalf"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              15k to Half Marathon
            </Typography>
          }
        />
        <FormControlLabel
          value="underMarathon"
          control={
            <Radio
              name="underMarathon"
              checked={props.distanceStatus === "underMarathon"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Half to Full Marathon
            </Typography>
          }
        />
        <FormControlLabel
          value="underUltra"
          control={
            <Radio
              name="underUltra"
              checked={props.distanceStatus === "underUltra"}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Ultra Marathon
            </Typography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
