import React, {useState, useEffect} from "react";
import Routes from "./Routes";
import {AppContext} from "./libs/contextLib";
import {Auth} from "aws-amplify";
import {onError} from "./libs/errorLib";

import theme from "./components/Theme";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {API} from "aws-amplify";

import {ThemeProvider} from "@material-ui/core/styles";
// mock the API response for now
//import apiResponse from "./mocks/users/get";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    // function loadUserMessages(user) {
    //   let userId = user.idToken.payload.sub;
    //   return API.get("virtual-tm", `/users/${userId}/messages?unread=true`);
    // }

    function loadUserProfile(user) {
      return API.get("virtual-tm", `/users/profile`);
    }
    try {
      let user = await Auth.currentSession();
      userHasAuthenticated(true);
      let userProfile = await loadUserProfile(user);
      setCurrentUserProfile(userProfile);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <ThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            currentUserProfile,
            setCurrentUserProfile
          }}
        >
          <Header />
          <Routes />
          <Footer />
        </AppContext.Provider>
      </ThemeProvider>
    )
  );
}

export default App;
