import React, {useState} from "react";
import {Formik, Field, Form} from "formik";
// import {FormControlLabel, Radio} from "@material-ui/core";
// import {RadioGroup} from "formik-material-ui";

import {makeStyles} from "@material-ui/core/styles";
import {
  Container,
  Button,
  Avatar,
  Typography,
  Grid,
  Link
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {TextField} from "formik-material-ui";
import * as yup from "yup";

import {useHistory} from "react-router-dom";
import {useAppContext} from "../libs/contextLib";
import {onError} from "../libs/errorLib";
import {Auth, API} from "aws-amplify";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  confirmationContainer: {
    marginTop: theme.spacing(12)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));
const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  lastName: "",
  firstName: "",
  gender: "",
  birthdate: ""
};

// form validation rules
const validationSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  password: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match.")
    .required("Required"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Required")
});

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const [formState, setFormState] = useState(initialValues);
  const {userHasAuthenticated} = useAppContext();

  async function handleSignUpSubmit(event, actions) {
    actions.setSubmitting(true);
    try {
      const newUser = await Auth.signUp({
        username: event.email,
        password: event.password,
        attributes: {
          email: event.email,
          gender: "male",
          name: "Seth Geoghegan",
          birthdate: "1979-11-21"
        }
      });
      setFormState(event);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      actions.setSubmitting(false);
    }
  }

  async function handleConfirmationSubmit(event, actions) {
    actions.setSubmitting(true);

    try {
      await Auth.confirmSignUp(formState.email, event.confirmationCode);
      await Auth.signIn(formState.email, formState.password);
      await API.post("virtual-tm", `/users/signup`);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      actions.setSubmitting(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Container
        className={classes.confirmationContainer}
        component="main"
        maxWidth="sm"
      >
        <div>
          <Formik
            initialValues={{confirmationCode: ""}}
            onSubmit={(data, {setSubmitting}) => {
              handleConfirmationSubmit(data, {setSubmitting});
            }}
          >
            {({submitForm, values, errors, isSubmitting}) => (
              <Form>
                <Grid container className={classes.formContainer}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="confirmationCode"
                      type="text"
                      label="Confirmation Code"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Sign Up
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    );
  }

  return (
    <div>
      {newUser === null ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(data, {setSubmitting}) => {
                handleSignUpSubmit(data, {setSubmitting});
              }}
            >
              {({submitForm, values, errors, isSubmitting}) => (
                <Form>
                  <Grid container className={classes.formContainer} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="firstName"
                        type="text"
                        label="First Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="lastName"
                        type="text"
                        label="Last Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="password"
                        label="Password"
                        name="password"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="password"
                        label="Confirm Password"
                        name="confirmPassword"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    fullWidth
                  >
                    Submit
                  </Button>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Link href="login" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      ) : (
        renderConfirmationForm()
      )}
    </div>
  );
}
