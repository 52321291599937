import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import ResultCard from "./ResultCard";
// import runMap from "../assets/runmap.png";
// import avatar from "../assets/avatar.png";
// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";

// mock the API response for now
import apiResponse from "../mocks/latestResults/list";

const useStyles = makeStyles(theme => ({
  heading: {},
  latestResultsContainer: {
    marginTop: theme.spacing(8)
  },
  resultCard: {
    padding: theme.spacing(0, 5)
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 5)
  }
}));

export default function Header(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h2" className={classes.heading} align="center">
        Latest Results
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={classes.latestResultsContainer}
      >
        {apiResponse.data.slice(0, 2).map((result, index) => (
          <iframe
            title={index}
            height="405"
            width="500"
            frameborder="0"
            allowtransparency="true"
            scrolling="no"
            src="https://www.strava.com/activities/4659349372/embed/969bfd58b8f3a7cdb0c9618e2f986e77bc049620"
          ></iframe>
// <Grid item xs={12} md={4} key={index}>
//   <div className={classes.item}>
//     <ResultCard
//       athleteName={result.athleteName}
//       raceTitle={result.title}
//       performedAt={result.performedAt}
//       runMap={runMap}
//       raceDistance={result.distance}
//       racePace={result.pace}
//       raceTime={result.time}
//       raceInfoUrl={result.moreInfoUrl}
//       avatar={result.avatar_url}
//     />
//   </div>
// </Grid>

        ))}
      </Grid>
    </React.Fragment>
  );
}
