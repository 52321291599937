import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import * as yup from "yup";

import {Formik, Field, Form} from "formik";
import {TextField} from "formik-material-ui";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../libs/contextLib";
import {onError} from "../libs/errorLib";

// mock the API response for now
import apiResponse from "../mocks/users/get";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const initialValues = {
  email: "",
  password: ""
};

// form validation rules
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  password: yup.string().required("Required")
});

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const {userHasAuthenticated, setCurrentUserProfile} = useAppContext();

  async function handleLoginSubmit(event, actions) {
    actions.setSubmitting(true);
    try {
      const user = await Auth.signIn(event.email, event.password);
      console.log(user);
      userHasAuthenticated(true);
      // mock user profile API call
      setCurrentUserProfile(apiResponse);
      history.push("/");
    } catch (e) {
      onError(e);
      actions.setSubmitting(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, {setSubmitting}) => {
            handleLoginSubmit(data, {setSubmitting});
          }}
        >
          {({submitForm, values, errors, isSubmitting}) => (
            <Form>
              <Grid container className={classes.formContainer} spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                fullWidth
              >
                Submit
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="login/reset" variant="body2">
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
