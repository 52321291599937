import React from "react";
import ProductHero from "../components/ProductHero";
import HowItWorks from "../components/HowItWorks";
import PopularRacesGrid from "../components/PopularRacesGrid";
import LatestResults from "../components/LatestResults";

export default function Home() {
  return (
    <div>
      <ProductHero />
      <HowItWorks />
      <PopularRacesGrid />
      <LatestResults />
    </div>
  );
}
