import React from "react";
import StravaLogin from "../components/StravaLogin";

export default function Connections() {
  function onFailed() {
    console.log("FAILED");
  }
  function onSuccess() {
    console.log("SUCCESS");
  }

  return (
    <div>
      <h1>Connections</h1>

      <StravaLogin
        redirectUrl="https://www.signupandrace.com/strava/auth_callback/"
        onFailure={onFailed}
        onSuccess={onSuccess}
        requestTokenUrl="/auth_callback"
      />
    </div>
  );
}
