import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import registerIcon from "../assets/website.png";
import raceIcon from "../assets/sport.png";
import resultsIcon from "../assets/podium.png";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5)
  },
  howItWorksContainer: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    alignItems: "center"
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  button: {
    minWidth: 200,
    marginTop: "50px"
  },
  stepsContainer: {
    marginTop: theme.spacing(8)
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180
  },
  stepIcon: {
    height: 50
  }
}));

export default function Header(props) {
  const classes = useStyles();
  var steps = [
    {
      id: "step1",
      title: "STEP 1: REGISTER",
      icon: registerIcon,
      text:
        "Chose from a variety of races created by the community. " +
        "Don’t see an event that meets your needs? " +
        "Create your own race and invite others to join!"
    },
    {
      id: "step2",
      title: "STEP 2: RACE",
      icon: raceIcon,
      text:
        "Lace up the shoes and give it your best effort! " +
        "Inside, outside, all activities count!"
    },
    {
      id: "step3",
      title: "STEP 3: SUBMIT RESULTS",
      icon: resultsIcon,
      text:
        "Submit your results to see how you compare to the competition. " +
        "You can automatically upload results by syncing with your " +
        "favorite activity tracker"
    }
  ];

  return (
    <section>
      <Grid
        container
        direction="column"
        className={classes.howItWorksContainer}
      >
        <Grid item>
          <Typography variant="h2">How It Works</Typography>
        </Grid>
        <Grid container direction="row" className={classes.stepsContainer}>
          {steps.map(step => (
            <Grid item align="center" xs={12} md={4} key={step.id}>
              <div className={classes.item}>
                <img
                  className={classes.stepIcon}
                  src={step.icon}
                  alt="register"
                />
                <Typography variant="h6" className={classes.title}>
                  {step.title}
                </Typography>
                <Typography variant="subtitle1" align="center">
                  {step.text}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            href="/races"
          >
            Find A Race
          </Button>
        </Grid>
      </Grid>
    </section>
  );
}
