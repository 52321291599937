import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import MaterialTable from "material-table";
import Link from "@material-ui/core/Link";
import stravaLogo from "../assets/strava_logo_orange.png";
import {timeDisplay, pacePerDistance} from "../libs/helperLib";

const useStyles = makeStyles(theme => ({
  profile: {
    minWidth: "200px"
  },
  stravaLink: {
    color: "#FC4C02"
  }
}));

export default function ResultsTable(props) {
  const classes = useStyles();
  const {race, results} = props;

  function profileField(result) {
    return (
      <Grid container direction="row" className={classes.profile} spacing={1}>
        <Grid item>
          <Avatar
            alt={result.athlete.name}
            src={result.athlete.avatar_url}
            className={classes.large}
          />
        </Grid>
        <Grid item>
          <div>
            <Typography variant="caption">{result.athlete.name}</Typography>
          </div>
          <div>
            <Typography variant="caption">{result.athlete.location}</Typography>
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div style={{maxWidth: "100%"}}>
      <MaterialTable
        data={results}
        options={{padding: "dense", paging: false}}
        title="Results"
        columns={[
          {
            title: "HiddenName",
            field: "athlete.name",
            hidden: true,
            searchable: true
          },
          {
            title: "Name",
            field: "performed_by",
            sorting: false,
            render: rowData => profileField(rowData)
          },
          {
            title: "Pace",
            field: "pace",
            sorting: false,
            render: rowData => pacePerDistance(rowData.moving_time, race.dist)
          },
          {
            title: "Moving Time",
            field: "moving_time",
            type: "numeric",
            sorting: true,
            defaultSort: "asc",
            render: rowData => timeDisplay(rowData.moving_time)
          },
          // {title: "Overall", field: "overall_place", type: "numeric"},
          // {title: "Gender", field: "gender_place", type: "numeric"},
          // {title: "Division", field: "division_place", type: "numeric"}
          // {
          //   title: "Pace",
          //   render: rowData => rowData.pace,
          //   sorting: false
          // },
          {
            field: "activity_id",
            sorting: false,
            render: rowData => (
              <Link
                href={`https://www.strava.com/activities/${rowData.activity_id}`}
                underline="none"
                target="_blank"
                rel="noopener"
                className={classes.stravaLink}
              >
                View on{" "}
                <img
                  height="10"
                  width="50"
                  src={stravaLogo}
                  alt="strava logo"
                />
              </Link>
            )
          }
        ]}
      />
    </div>
  );
}
