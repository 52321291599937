import React from "react";
//import {makeStyles} from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Divider from "@material-ui/core/Divider";
//import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {prettyPrintDistance} from "../libs/helperLib";
import Grid from "@material-ui/core/Grid";
import {format} from "date-fns";
import {FixedSizeList} from "react-window";

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%",
//     maxWidth: "150ch",
//     backgroundColor: theme.palette.background.paper
//   },
//   inline: {
//     display: "inline"
//   },
//   date: {
//     width: 50,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     marginRight: theme.spacing(4),
//     textTransform: "uppercase"
//   }
// }));

function renderRow(props) {
  const {index, style} = props;

  return (
    <ListItem
      button
      component="a"
      href={props.data[index].url}
      style={style}
      key={index}
    >
      <Typography component="span" variant="subtitle1" color="textPrimary">
        {format(new Date(props.data[index].s_date), "LLL d yyyy")}
      </Typography>
      <Grid container direction="column">
        <Typography variant="h5" color="textPrimary">
          {props.data[index].title}
        </Typography>
        <Typography variant="h5">
          {prettyPrintDistance(props.data[index].distance)}{" "}
          {props.data[index].type}
        </Typography>
        <Typography variant="body2">
          {"Hosted by " + props.data[index].created_by.name}
        </Typography>
      </Grid>
    </ListItem>
  );
}

export default function SearchResultList(props) {
  //const classes = useStyles();

  return (
    <FixedSizeList
      itemData={props.races}
      height={600}
      width={800}
      itemSize={100}
      itemCount={props.races.length}
      renderList={props.renderList}
    >
      {renderRow}
    </FixedSizeList>
  );
}
