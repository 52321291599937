import React, {useState} from "react";
import {ResetPasswordStep1} from "./ResetPasswordStep1";
import {ResetPasswordStep2} from "./ResetPasswordStep2";
import {Success} from "./Success";
//import {Grid} from "@material-ui/core";
export default function ResetPWForm() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    confirmationCode: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 2:
      return (
        <ResetPasswordStep1
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
        />
      );
    case 1:
      return (
        <ResetPasswordStep2
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    default:
      return <Success />;
  }
}
