import {createMuiTheme} from "@material-ui/core/styles";

const tmBlue = "#0B72B9";
const tmOrange = "#FFBA60";
const tmWhite = "#FfFFFF";
const tmBlack = "#000000";
// const tmBlueGrey = "#90a4ae";
// const tmPink = "#";


export default createMuiTheme({
  palette: {
    common: {
      blue: `${tmBlue}`,
      orange: `${tmOrange}`,
      white: `${tmWhite}`,
      black: `${tmBlack}`
    },
    primary: {
      main: `${tmBlue}`
    }
  },
  typography: {
    tab: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: 400,
      fontSize: "1rem"
    }
  }
});
