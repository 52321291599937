const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "dev-signup-and-run-s3-uploads4f6eb0fd-1f0djtukpsp0v",
    IMG_URL: "https://d56ru6of03pvl.cloudfront.net/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://dev-api.signupandrace.com"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_F6JNxuZC5",
    APP_CLIENT_ID: "d15su1038f1n0a2n98v7iiubc",
    IDENTITY_POOL_ID: "us-west-2:07990189-e349-4e3e-b6d7-44e21449f80a"
  }
};

const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "prod-signup-and-run-s3-uploads4f6eb0fd-1f4e1nlzkp0or", //"prod-virtual-tm-infra-s3-uploads4f6eb0fd-l9j11i2dvzhu",
    IMG_URL: "https://d37ubecf3zqupd.cloudfront.net/"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_kVPSHM0YG",
    APP_CLIENT_ID: "7k3c3lk0bd1ahe1ec169rsehr2",
    IDENTITY_POOL_ID: "us-west-2:00e68925-655d-4465-8ff5-3014f605fc24"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.signupandrace.com"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
