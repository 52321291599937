import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    opacity: 0.54,
    width: "100%",
    height: "60px",
    zIndex: 1302,
    position: "relative",
    marginTop: theme.spacing(8)
  },
  mainContainer: {
    position: "absolute"
  },
  link: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "1em"
  },
  gridItem: {
    margin: "1em"
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container justify="center" className={classes.mainContainer}>
        <Grid item className={classes.gridItem}>
          <Grid container direction="column">
            <Grid item className={classes.link}>
              © 2020 SignUpAndRace
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
