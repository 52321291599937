import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Link} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textCenter: {
    textAlign: "center"
  }
}));

export const Success = () => {
  const classes = useStyles();
  return (
    <div className={classes.textCenter}>
      <Typography variant="h5">Password successfully reset.</Typography>
      Please <Link href="/login">login</Link> to continue.
    </div>
  );
};
