import React, {useState, useEffect} from "react";
import {useAppContext} from "../libs/contextLib";
import {
  Toolbar,
  AppBar,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  Slide
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {fade} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import ProfileButton from "../components/ProfileButton";
import NotificationButton from "../components/NotificationButton";
import logo from "../assets/logo.png";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.mixins.toolbar
  },
  logo: {
    height: "3em",
    [theme.breakpoints.down("sm")]: {
      height: "2em"
    }
  },
  tabContainer: {
    marginLeft: "auto"
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "25px",
    color: theme.palette.common.white
  },
  drawerIcon: {
    height: "40px",
    width: "40px"
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawer: {
    backgroundColor: theme.palette.common.white
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "black"
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: fade(theme.palette.common.black, 0.25)
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const {isAuthenticated} = useAppContext();
  const theme = useTheme();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
// const [currentUser, setCurrentUser] = useState(null);
// const {userHasAuthenticated} = useAppContext();


  const tabClicked = (e, newIndex) => {
    setActiveTabIndex(newIndex);
  };

  const routes = [
    {name: "Home", link: "/", activeIndex: 0},
    {name: "Races", link: "/races", activeIndex: 1},
    {name: "About", link: "/about", activeIndex: 2},
    {name: "Contact", link: "/contact", activeIndex: 3},
    {name: "Login", link: "/login", activeIndex: 4},
    {name: "Sign Up", link: "/signup", activeIndex: 5}
  ];

  useEffect(() => {
    // set the active tab
    routes.forEach(route => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (activeTabIndex !== route.activeIndex) {
            setActiveTabIndex(route.activeIndex);
          }
          break;
        default:
          break;
      }
    });
  }, [activeTabIndex, routes]);

  const tabs = (
    <Tabs
      value={activeTabIndex}
      onChange={tabClicked}
      className={classes.tabContainer}
    >
      <Tab
        className={classes.tab}
        component={Link}
        to="/"
        label="Home"
        value={0}
      />
      <Tab className={classes.tab} component={Link} to="/races" label="Races" />
      <Tab className={classes.tab} component={Link} to="/about" label="About" />
      <Tab
        className={classes.tab}
        component={Link}
        to="/contact"
        label="Contact"
      />

      {isAuthenticated && <NotificationButton />}
      {isAuthenticated && <ProfileButton />}
      {!isAuthenticated && (
        <Tab
          className={classes.tab}
          component={Link}
          to="/login"
          label="Login"
        />
      )}
      {!isAuthenticated && (
        <Tab
          className={classes.tab}
          component={Link}
          to="/signup"
          label="Sign Up"
        />
      )}
    </Tabs>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{paper: classes.drawer}}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {routes.map((route, index) => (
            <ListItem
              key={`${route}${route.activeIndex}`}
              divider
              button
              component={Link}
              to={route.link}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <ListItemText className={classes.drawerItem} disableTypography>
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar} position="fixed" elevation={0}>
          <Toolbar disableGutters>
            <img alt="logo" className={classes.logo} src={logo} />
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}
