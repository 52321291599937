import React, {useState, useEffect, useReducer} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {onError} from "../../libs/errorLib";
import {
  searchReducer,
  SORT_BY_DISTANCE_ASC,
  SORT_BY_DISTANCE_DESC,
  SORT_BY_DATE_ASC,
  SORT_BY_DATE_DESC,
  UPDATE_STATE,
  FILTER_BY_DISTANCE
} from "../../reducers/searchReducer";

import EventFilter from "../../components/EventFilter";
import SearchResultList from "../../components/SearchResultList";
import {API} from "aws-amplify";

import backgroundImage from "../../assets/filip-mroz-sgtxFOiBZmQ-unsplash.png";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
    height: "40vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  gridList: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  },
  pageHeading: {
    marginBottom: theme.spacing(4)
  },
  searchField: {
    marginRight: "50px",
    minWidth: "500px"
  },
  sortField: {
    minWidth: "200px"
  }
}));

// get races between distance1 and distance2
function getRaces(d1, d2) {
  return API.get("virtual-tm", `/races/search?d1=${d1}&d2=${d2}`);
}

function asDistance(distanceSelection) {
  switch (distanceSelection) {
    case "under1m":
      return 1609;
    case "under5k":
      return 5000;
    case "under15k":
      return 15000;
    case "underHalf":
      return 21077;
    case "underMarathon":
      return 42155;
    case "underUltra":
      return 200000;
    default:
      return 5000;
  }
}

export default function Races() {
  const classes = useStyles();
  const initialState = {races: []};

  const [renderList, setRenderList] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [distanceFilter, setDistanceFilter] = useState("underUltra");

  const [raceList, dispatch] = useReducer(searchReducer, initialState);

  // TODO had to hack this useEffect hook to get linter to be happy
  // probably does not work now thatI've added dependencies (was empty array).
  // also used a console.log and call to setdistanceFilter to satisfy unused variables.
  useEffect(() => {
    console.log(searchTerm);
    setDistanceFilter("TODO: FIX THIS");
    async function onLoad() {
      try {
        let races = await getRaces(0, asDistance(distanceFilter));
        dispatch({type: UPDATE_STATE, races: races.data});
      } catch (e) {
        onError(e);
      }
    }
    onLoad();
  }, [distanceFilter, searchTerm]);

  const handleDistanceFilterChange = event => {
    dispatch({
      type: FILTER_BY_DISTANCE,
      distance: asDistance(event.target.value)
    });
    setRenderList(!renderList);
  };

  const handleSortByChange = event => {
    dispatch({
      type: event.target.value
    });
    setRenderList(!renderList);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h2" align="center" className={classes.pageHeading}>
        Events
      </Typography>
      <Grid container direction="row" className={classes.content}>
        <Grid item xs={2}>
          <EventFilter
            handleDistanceChange={handleDistanceFilterChange}
            distanceStatus={distanceFilter}
          />
        </Grid>
        <Grid item container xs={10}>
          <Grid item xs={6} className={classes.searchField}>
            <TextField
              label="Search"
              fullWidth
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={SORT_BY_DATE_ASC}
              onChange={handleSortByChange}
              className={classes.sortField}
            >
              <MenuItem value={SORT_BY_DISTANCE_ASC}>
                Race Distance (ascending)
              </MenuItem>
              <MenuItem value={SORT_BY_DISTANCE_DESC}>
                Race Distance (descending)
              </MenuItem>
              <MenuItem value={SORT_BY_DATE_ASC}>
                Start Date (ascending)
              </MenuItem>
              <MenuItem value={SORT_BY_DATE_DESC}>
                Start Date (descending)
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={9}>
            <div className={classes.gridList}>
              <SearchResultList
                races={raceList || []}
                renderList={renderList}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
