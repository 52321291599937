import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
//import AccountInformation from "../components/AccountInformation4";
import StravaLogin from "../components/StravaLogin";

function onFailed() {
  console.log("FAILED");
}
function onSuccess() {
  console.log("SUCCESS");
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  accountInfo: {
    padding: "1em"
  }
}));

export default function Profile() {
  const classes = useStyles();

  // this would come from the API and/or context
  // const [userProfile, setUserProfile] = useState({
  //   email: "seth.geoghegan@gmail.com",
  //   name: "Seth Geoghegan",
  //   username: "SethG"
  // });

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.accountInfo}>
          <Typography variant="h4">Connections</Typography>
          <StravaLogin
            redirectUrl="https://www.signupandrace.com/strava/auth_callback/"
            onFailure={onFailed}
            onSuccess={onSuccess}
            requestTokenUrl="/strava/auth_callback"
          />
        </Grid>
      </Grid>
    </div>
  );
}
