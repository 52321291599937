import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AuthCallback() {
  let urlParams = useQuery();

  useEffect(() => {
    // get the URL parameters which will include the code and scope parameters
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const scope = urlParams.get("scope");
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage(
        {
          source: "signupandrace",
          payload: {state: state, code: code, scope: scope}
        },
        window.location.origin
      );
      // close the popup
      window.close();
    }
  });

  return <div>Connecting with Strava!</div>;
}
