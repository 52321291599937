import React, {useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import {useAppContext} from "../libs/contextLib";

export default function ProfileButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const {currentUserProfile} = useAppContext();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Avatar
          alt={currentUserProfile.fullname}
          src={currentUserProfile.avatar_url}
        ></Avatar>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        transformOrigin={{vertical: "top", horizontal: "center"}}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link href="/profile" color="inherit">
            Profile
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="/settings" color="inherit">
            My Account
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="/logout" color="inherit">
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
