import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import {API} from "aws-amplify";
import {onError} from "../libs/errorLib";
import {imgUrl} from "../libs/helperLib";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap"
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "35vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100
    },
    "&:hover": {
      zIndex: 1
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15
    },
    "&:hover $imageMarked": {
      opacity: 0
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor"
    }
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  },
  header: {
    marginTop: theme.spacing(8)
  }
}));

export default function PopularRacesGrid(props) {
  const classes = useStyles();
  const [featuredRaces, setFeaturedRaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function featuredRaces() {
      return API.get("virtual-tm", `/races/featured`);
    }

    async function onLoad() {
      setLoading(true);
      try {
        let races = await featuredRaces();

        setFeaturedRaces(races);
        setLoading(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  return (
    <React.Fragment>
      <Container className={classes.root} component="section">
        <Divider variant="middle" />
        <Typography
          className={classes.header}
          variant="h2"
          marked="center"
          align="center"
        >
          Featured Races
        </Typography>
        <div className={classes.images}>
          {!loading &&
            featuredRaces.map(race => (
              <ButtonBase
                href={race.url}
                key={race.title}
                className={classes.imageWrapper}
                style={{
                  width: "33%"
                }}
              >
                <div
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${imgUrl(race.img, {
                      edits: {
                        resize: {
                          width: 300,
                          height: 300,
                          fit: "cover"
                        }
                      }
                    })})`
                  }}
                />
                <div className={classes.imageBackdrop} />
                <div className={classes.imageButton}>
                  <Typography
                    component="h3"
                    variant="h6"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {race.title}
                    <div className={classes.imageMarked} />
                  </Typography>
                </div>
              </ButtonBase>
            ))}
        </div>
      </Container>
    </React.Fragment>
  );
}
