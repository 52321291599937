import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import * as yup from "yup";
import {Formik, Form, Field} from "formik";
import {Button, LinearProgress} from "@material-ui/core";
import {TextField, Select, SimpleFileUpload} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {endOfDay, startOfDay} from "date-fns";

import {API} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {onError} from "../../libs/errorLib";
import {s3Upload} from "../../libs/awsLib";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: "1",
    marginTop: "100px"
  },
  raceForm: {},
  inputFeedback: {
    color: "red",
    marginTop: ".25rem",
    fontSize: ".75rem"
  }
}));

const initialValues = {
  title: "",
  startDate: "",
  endDate: "",
  registrationStartDate: "",
  registrationEndDate: "",
  tag: "",
  distance: "",
  eventDescription: "",
  bgImgFile: null
};

// form validation rules
const validationSchema = yup.object({
  title: yup.string().required("Required"),
  tag: yup.string().required("Required"),
  eventDescription: yup.string().required("Required"),
  distance: yup.string().required("Required")
});

export default function NewRace() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStartDate] = useState(new Date());
  const [selectedEndDate] = useState(new Date());
  const [selectedRegistrationStartDate] = useState(new Date());
  const [selectedRegistrationEndDate] = useState(new Date());

  async function handleSubmit(values) {
    const bgImgKey = values.bgImgFile ? await s3Upload(values.bgImgFile) : null;
    let race = await createRace(values, bgImgKey);
    history.push("/races/" + race.id);
  }

  async function createRace(race, bgImgKey) {
    var startDate = startOfDay(Date.parse(race.startDate));
    var endDate = endOfDay(Date.parse(race.endDate));
    var registrationStartDate = endOfDay(
      Date.parse(race.registrationStartDate)
    );
    var registrationEndDate = endOfDay(Date.parse(race.registrationEndDate));

    try {
      const args = {
        body: {
          title: race.title,
          dist: race.distance,
          s_date: startDate.toISOString(),
          e_date: endDate.toISOString(),
          rs_date: registrationStartDate.toISOString(),
          re_date: registrationEndDate.toISOString(),
          tag: race.tag,
          desc: race.eventDescription
        }
      };

      if (bgImgKey) args.body.bg_img = bgImgKey;

      return API.post("virtual-tm", "/races/create", args);
    } catch (e) {
      console.error(e);
      onError(e);
    }
  }

  return (
    <Container className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting}) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({submitForm, isSubmitting, errors, touched, setFieldValue}) => (
            <Form>
              <Grid container direction="column" spacing={6}>
                <Grid item>
                  <Typography variant="h4">Create Event</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="title"
                    label="Race Name"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="tag"
                    label="Race Tag"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    id="startDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={selectedStartDate}
                    InputAdornmentProps={{position: "start"}}
                    onChange={(
                      date,
                      dateString //handleStartDateChange
                    ) => setFieldValue("startDate", dateString)}
                    disablePast
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    id="endDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={selectedEndDate}
                    InputAdornmentProps={{position: "start"}}
                    onChange={(
                      date,
                      dateString //handleEndDateChange
                    ) => setFieldValue("endDate", dateString)}
                    minDate={selectedEndDate}
                    disablePast
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    id="registrationStartDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Registration Start Date"
                    format="MM/dd/yyyy"
                    value={selectedRegistrationStartDate}
                    InputAdornmentProps={{position: "start"}}
                    onChange={(
                      date,
                      dateString //handleRegStartDateChange()
                    ) => setFieldValue("registrationStartDate", dateString)}
                    minDate={selectedRegistrationStartDate}
                    disablePast
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    id="registrationEndDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Registration End Date"
                    format="MM/dd/yyyy"
                    value={selectedRegistrationEndDate}
                    InputAdornmentProps={{position: "start"}}
                    onChange={(
                      date,
                      dateString //handleRegEndDateChange
                    ) => setFieldValue("registrationEndDate", dateString)}
                    minDate={selectedRegistrationEndDate}
                    disablePast
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="raceDistance">Distance</InputLabel>

                  <Field
                    fullWidth
                    variant="outlined"
                    component={Select}
                    name="distance"
                    inputProps={{
                      id: "distance"
                    }}
                  >
                    <MenuItem value={"400"}>400m</MenuItem>
                    <MenuItem value={"800"}>800m</MenuItem>
                    <MenuItem value={"1000"}>1k</MenuItem>
                    <MenuItem value={"1609"}>1 mile</MenuItem>
                    <MenuItem value={"3218"}>2 mile</MenuItem>
                    <MenuItem value={"5000"}>5k</MenuItem>
                    <MenuItem value={"10000"}>10k</MenuItem>
                    <MenuItem value={"21097"}>Half Marathon</MenuItem>
                    <MenuItem value={"42195"}>Marathon</MenuItem>
                  </Field>
                  {errors.distance && touched.distance && (
                    <div className={classes.inputFeedback}>
                      {errors.distance}
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={TextField}
                    label="Event Description"
                    name="eventDescription"
                    variant="outlined"
                    InputProps={{
                      multiline: true,
                      rows: 10
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={SimpleFileUpload}
                    name="bgImgFile"
                    label="Banner Image"
                  />
                </Grid>
                {isSubmitting && <LinearProgress />}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Create Event
              </Button>
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </Container>
  );
}
