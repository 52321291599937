import React, {useEffect} from "react";
import {useAppContext} from "../libs/contextLib";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";

export default function Logout() {
  const {userHasAuthenticated} = useAppContext();
  const history = useHistory();

  useEffect(() => {
    handleLogout();
  });

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/");
  }

  return <h1>Logging out</h1>;
}
