export const SORT_BY_DISTANCE_ASC = "DISTANCE_ASC";
export const SORT_BY_DISTANCE_DESC = "DISTANCE_DESC";
export const SORT_BY_DATE_ASC = "DATE_ASC";
export const SORT_BY_DATE_DESC = "DATE_DESC";
export const UPDATE_STATE = "UPDATE_STATE";
export const FILTER_BY_DISTANCE = "DISATANCE_FILTER";

export const searchReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return action.races;
    case SORT_BY_DISTANCE_ASC:
      return state.sort((a, b) => (a.distance > b.distance ? 1 : -1));
    case SORT_BY_DISTANCE_DESC:
      return state.sort((a, b) => (a.distance > b.distance ? -1 : 1));
    case SORT_BY_DATE_ASC:
      return state.sort((a, b) => (a.s_date > b.s_date ? 1 : -1));
    case SORT_BY_DATE_DESC:
      return state.sort((a, b) => (a.s_date > b.s_date ? -1 : 1));
    case FILTER_BY_DISTANCE:
      return state.filter(race => race.distance <= action.distance);
    default:
      return state;
  }
};
